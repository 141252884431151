//TODO: clean up the upload function, hard to read
//and getting maybe unhandled exception errors
function UploaderFactory($rootScope, $timeout, $http, $log, $q, $window, SETTINGS, socketIO) {
  const _baseUrl = SETTINGS.apiEndpoint + 'jobs/upload/';
  // this corresponds to the s3 aws sdk event name
  // if we employ any other api's, we will still send this event to reduce confusion
  const socketEvent = 'httpUploadProgress';

  const _jobs = {};

  const uploader = {};

  Object.defineProperty(uploader, 'jobs', {
    get: () => _jobs
  });

  uploader.listen = listen;

  uploader.upload = (job, bucketName) => {
    if(!(job && job._id && bucketName)) {
      const err = new Error('job._id and bucketName required');
      $log.error(err);
      return $q.reject(err);
    }

    const jobID = job._id;

    if(_jobs[jobID] && _jobs[jobID].upload) {
      return _jobs[jobID].upload;
    }

    _jobs[jobID] = { upload: {}, cancelQuery: $q.defer(), running: true, completed: false, error: false, progress: 0 };

    _jobs[jobID].upload = $http.post(_baseUrl + jobID, {
      Bucket: bucketName,
      type: 's3',
    }, { 
      timeout: _jobs[jobID].cancelQuery.promise,
    }).then(() => {
      return _jobs[jobID];
    }).catch((rejection) => {
      if(rejection.status > 0) {
        _jobs[jobID].error = rejection;
      }
    }).finally(() => {
      if(_jobs[jobID]) {
        // When we cancel the request, _jobs[jobID] is deleted
        _jobs[jobID].completed = true;
      }
    });

    return _jobs[jobID].upload;
  };

  uploader.cancelUpload = (job) => {
    if(!(job && job._id)) {
      const err = new Error('job._id required');
      $log.error(err);
      return err;
    }

    if(_jobs[job._id]) {
      if(!_jobs[job._id].completed && _jobs[job._id].cancelQuery) {
        _jobs[job._id].cancelQuery.resolve();
      }

      delete _jobs[job._id];
    }

    return null;
  };

  let timeoutPromise;
  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.upload.toS3.service.js');

    socket.on(socketEvent, (response) => {
      // May result in us missing events
      // if(timeoutPromise) {
      //   $timeout.cancel(timeoutPromise);
      // }

        
        // $log.debug('received', response);
        

        // $timeout( () => {
          $rootScope.$evalAsync(() => {
            const data = JSON.parse(response);

            if(_jobs[data._id]) {
              $window.requestAnimationFrame( () => {
                _jobs[data._id].progress = data.data.loaded * 100 / (data.data.total || file.Size);
              });
            }
          });
        // }, 100);
        
    });
  }

  // could be dangerous; assumes only this servce listens to these events
  function _removeListeners(socket) {
    $log.debug('removing socket io listeners in jobs.upload.toS3.service.js');
    socket.removeAllListeners(socketEvent);
    if(timeoutPromise) {
      $timeout.cancel(timeoutPromise);
    }
  }

  function listen() {
    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('sq.services.socketIO:unauthorized in job.upload.toS3.service');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      $log.debug('sq.services.socketIO:authenticated in job.upload.toS3.service');
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('sq.services.socketIO:disconnected in job.upload.toS3.service');

      _removeListeners(socket);
    });
  }

  return uploader;
}

angular.module('sq.jobs.upload.toS3.service', ['sq.config', 'sq.services.socketIO']).factory('uploadToS3', UploaderFactory);